import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:盘点单据打印
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16055
 */
export function countOrderPrint(data) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order/print', data);
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
