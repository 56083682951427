// 打印复盘单的盘点单时，盘点单表头调整为复盘单。
const TYPE = ['RE_COUNT'];

export default {
  type: 'COUNT_ORDER_LIST',
  sheetName: '盘点预约单',
  title: (type) => ({
    oneiline: false, // 是否一行显示
    sheetName: TYPE.includes(type) ? '复盘单' : '盘点预约单', // 打印单名称
    company: '鲜生活冷链物流有限公司', // 公司名称
  }),
};
