// 温层
const TEMPERAYURE_LIST = [{
  temperatureLayerCode: '',
  temperatureLayerName: '全部',
}, {
  temperatureLayerCode: 'NORMAL',
  temperatureLayerName: '常温',
}, {
  temperatureLayerCode: 'COLD',
  temperatureLayerName: '冷冻',
}, {
  temperatureLayerCode: 'REFRIGERATE',
  temperatureLayerName: '冷藏',
}, {
  temperatureLayerCode: 'CONSTANT',
  temperatureLayerName: '恒温',
}];
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
export {
  TEMPERATURE_LAYER, TEMPERAYURE_LIST,
};
