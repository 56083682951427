// 打印单公共信息配置
export default {
  info: [
    [{
      label: '盘点单号：',
      prop: 'countOrderNo',
      span: 16,
    }, {
      label: '盘点时间：',
      prop: 'startTime',
      span: 6,
    }],
  ],
  tableHeaders: [
    { label: '所属货主', prop: 'shipperName', width: 60 },
    { label: '温层', prop: 'temperatureLayer', width: 60 },
    { label: '库位', prop: 'storehouseCode', width: '5%' },
    { label: '货品名称', prop: 'goodsName', width: '5%' },
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', class: 'letter-spacing-1', width: '11.5%',
    },
    { label: '货品规格', prop: 'goodsSpecifications', width: '6%' },
    { label: '生产日期', prop: 'productDate', width: 80 },
    { label: '默认单位', prop: 'defaultUnit', width: '5%' },
    {
      label: '系统数量',
      width: '15%',
      children: [
        { label: '库存数量', prop: 'amountStr' },
        { label: '辅助数量', prop: 'auxiliaryAmount' },
      ],
    },
    {
      label: '终盘数量',
      width: '15%',
      children: [
        { label: '总数量', prop: 'doAmountStr' },
        { label: '辅助数量', prop: 'auxiliaryAmount' },
      ],
    },
    {
      label: '差异数量',
      width: '15%',
      children: [
        { label: '总数量', prop: 'diffAmountStr' },
        { label: '辅助数量', prop: 'auxiliaryAmount' },
      ],
    },
    { label: '备注', width: 50 },
  ],
  sign: [
    [{
      label: '盘盈总数：',
      width: '15',
    }, {
      prop: 'totalCountAdd',
      width: '35',
    }, {
      label: '盘亏总数：',
      width: '15',
    }, {
      prop: 'totalCountSub',
      width: '35',
    }],
    [{
      label: '盘点人：',
      width: '25',
    }, {
      label: '监盘人：',
      width: '25',
    }, {
      label: '客户：',
      width: '50',
    }],

  ],
};
