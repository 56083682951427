<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <PrintButton
        v-model="form.temperatureLayer"
        :type="TEMPERATURE_LAYER_BUTTON.type"
        :temperature-list="TEMPERAYURE_LIST"
        @change="temperatureChanged"
      />
      <div class="margin-24">
        <nh-button
          type="primary"
          @click="printPageByZoneCode"
        >
          按库区打印
        </nh-button>
        <el-select
          v-model="form.zoneId"
          filterable
          clearable
          placeholder="请选择库区"
          @change="zoneCodeChange"
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :print-list="printList"
      />
    </div>
    <!-- <Normal :print-list="printList" /> -->
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import PrintButton from '../components/PrintButton/PrintButton.vue';
import { countOrderPrint, warehouseZonePage } from './api';
import Normal from './normal/index.vue';
import config from './config';
import { TEMPERATURE_LAYER_BUTTON } from '../components/PrintButton/config';
import { TEMPERAYURE_LIST } from './fileds';

export default {
  components: {
    PrintButton,
    Normal,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      TEMPERAYURE_LIST,
      config,
      prints: [],
      form: {},
      loading: {
        init: false,
      },
      warehouseZonelist: [],
    };
  },
  created() {
    const { countOrderId, type } = this.$route.query;
    this.form.id = countOrderId;
    this.type = type;
    this.getSelectList();
    this.getCountOrder();
  },
  methods: {
    async getCountOrder() {
      try {
        const resp = await countOrderPrint({ ...this.form });
        this.prints = [{ ...resp, type: this.type, component: config.DEFAULT }];
      } catch (error) {
        this.prints = [];
      }
    },
    // 温层筛选
    temperatureChanged() {
      this.getCountOrder();
    },
    async getSelectList() {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { });
      this.warehouseZonelist = resp.records.map((item) => ({
        label: item.zoneName,
        value: item.id,
      }));
    },
    // 库区筛选
    zoneCodeChange() {
      this.getCountOrder();
    },
    printPageByZoneCode() {
      window.print();
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  margin: 0 auto;
  width: 1123px;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }
  }
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

.afterFooter {
  display: none;
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
