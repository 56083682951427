<template>
  <div class="footer-sign">
    <div
      v-for="(row, index) in sign"
      :key="index"
      class="row border"
    >
      <div
        v-for="(item, i) in row"
        :key="i"
        class="col"
        :style="{'flex': item.width}"
      >
        <p
          v-if="item.label"
          class="sign-label"
          :style="{textAlign: item.align}"
        >
          {{ item.label }}
        </p>
        <p v-else>
          {{ data[item.prop] }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sign: {
      type: Array,
      default: () => ([]),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

 <style lang="scss" scoped>
.footer-sign {
  border-top: 1px solid #000;

  .row {
    display: flex;
    font-size: 14px;

    .col {
      height: 28px;
      padding: 2px 0 2px 10px;

      .sign-label {
        font-weight: 600;
      }
    }
  }

  .row.border {
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;

    .col {
      border-right: 1px solid #000;
    }
  }
}
 </style>
