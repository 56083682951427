<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印一次性容器 -->
  <div class="printWj">
    <print-header
      :barcode="printList.countOrderNo"
      :title="config.title(printList.type)"
      :show-ext="false"
    />
    <print-info :info="fileds.info" :form="printList" />
    <print-table
      :headers="fileds.tableHeaders"
      :list="tableData"
      class="print-table"
    />
    <sign
      class="sign"
      :sign="fileds.sign"
      :data="signForm"
    />
  </div>
</template>
<script>
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintInfo from '../../components/PrintInfo/PrintInfo.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import config from './config';
import Sign from '../components/sign.vue';
import fileds from './fileds';
import { TEMPERATURE_LAYER } from '../fileds';

export default {
  components: {
    PrintHeader,
    PrintInfo,
    PrintTable,
    Sign,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      fileds,
      TEMPERATURE_LAYER,
      transferOrderNo: '',
      // tableData: [],
      // signForm: {},
    };
  },
  computed: {
    tableData() {
      return this.printList.details.map((item) => {
        const newItem = {
          ...item,
          temperatureLayer: this.TEMPERATURE_LAYER[item.temperatureLayer],
        };
        if (this.printList.isBlindCount) {
          newItem.amountStr = '';
        }
        return newItem;
      });
    },
    signForm() {
      return {
        totalCountAdd: `${this.printList.totalCountWholeUnitAdd}整单位${this.printList.totalCountZeroUnitAdd}零单位`,
        totalCountSub: `${this.printList.totalCountWholeUnitSub}整单位${this.printList.totalCountZeroUnitSub}零单位`,
      };
    },
  },

};
</script>

<style lang="scss">
.printWj {
  page-break-after: always;
  padding: 8px;

  .print-table {
    font-size: 14px;
    font-family: Microsoft YaHei, Tahoma, Verdana, STHeiTi, simsun, sans-serif;
    margin: 10px 0;

    th {
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      white-space: nowrap;
    }

    tbody td {
      height: auto;
      padding: 10px 0;
    }
  }
}

@media print {
  .print-btn {
    display: none;
  }
}
</style>
